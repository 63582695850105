import React, {useRef, useState} from "react"
import {Redirect, useParams} from "react-router-dom"

const Animation = () => {

    /*state = {
        started: false,
        hasError: false,
        played: false
    };*/

    const [played, setPlayed] = useState(false);
    const [error, setError] = useState(false);
    const [displayed, setDisplayed] = useState(false);
    const playedRef = useRef(played);
    const errorRef = useRef(error);

    let {type} = useParams();
    let typeImage = type;

    const products = {
        'legoland': {
            title: 'An incredible stay at the LEGOLAND<div class="rs"></div> Windsor Resort',
            desc: 'For 2 adults and 2 children'
        },
        'mosaic': {title: '40179 LEGO Mosaic Maker set', desc: 'To recreate your favourite photos as a mosaic'},
        'activity': {title: 'A free activity of your choice', desc: 'For adults and children'},
        'set_60324': {title: '60324 LEGO Mobile Crane'},
        'set_60316': {title: '60316 LEGO Police Station'},
        'set_60336': {title: '60336 LEGO Freight Train'},
        'set_43202': {title: '43202 LEGO The Madrigal House'},
        'set_10962': {title: '10962 LEGO Buzz Lightyear’s Planetary Mission'},
        'set_76408': {title: '76408 LEGO 12 Grimmauld Place'},
        'set_76400': {title: '76400 LEGO Hogwarts™ Carriage and Thestrals'},
        'set_76406': {title: '76406 LEGO Hungarian Horntail Dragon'},
        'set_10497': {title: '10497 LEGO Galaxy Explorer'},
        'set_10938': {title: '10938 LEGO Dinosaur Nursery'},
        'set_76950': {title: '76950 LEGO Triceratops Pickup Truck Ambush'},
        'set_41708': {title: '41708 LEGO Roller Disco Arcade'},
        'set_41711': {title: '41711 LEGO Emma\'s Art School'},
        'set_41703': {title: '41703 LEGO Friendship Tree House'},
        'set_60347': {title: '60347 LEGO Grocery Store'},
        'set_71772': {title: '71772 LEGO The Crystal King'},
        'set_76911': {title: '76911 LEGO 007 Aston Martin DB5'},
        'set_76912': {title: '76912 LEGO Fast & Furious 1970 Dodge Charger R/T'},
        'set_75329': {title: '75329 LEGO Death Star™ Trench Run Diorama'},
        'set_76217': {title: '76217 LEGO I am Groot'},
        'set_21186': {title: '21186 LEGO The Ice Castle'},
        'set_76178': {title: '76178 LEGO Daily Bugle'},
        'set_10283': {title: '10283 LEGO NASA Space Shuttle Discovery'},
        'set_60277': {title: '60277 LEGO Police Patrol Boat'},
        'set_10266': {title: '10266 LEGO NASA Apollo 11 Lunar Lander'},
        'set_71395': {title: '71395 LEGO Super Mario 64™ Question Mark Block'},
        'set_75980': {title: '75980 LEGO Attack on the Burrow'},
        'set_31120': {title: '31120 LEGO Medieval Castle'},
        'set_71374': {title: '71374 LEGO Nintendo Entertainment System™'},
        'set_71776': {title: '71776 LEGO Jay and Nya\'s Race Car EVO'},
        'set_76161': {title: '76161 LEGO 1989 Batwing'},
        'set_10270': {title: '10270 LEGO Bookshop'},
        'set_76391': {title: '76391 LEGO Hogwarts™ Icons - Collectors\' Edition'},
        'set_71741': {title: '71741 LEGO NINJAGO<div class="rs"></div> City Gardens'},
        'set_10273': {title: '10273 LEGO Haunted House'},
        'set_41693': {title: '41693 LEGO Surfer Beachfront'},
        'set_11022': {title: '11022 LEGO Space Mission'},
        'set_75192': {title: '75192 LEGO Millennium Falcon™'},
        'set_75313': {title: '75313 LEGO AT-AT™'},
        'set_76393': {title: '76393 LEGO Harry Potter™ & Hermione Granger™'},
        'set_43197': {title: '43197 LEGO The Ice Castle'},
    };

    if (type.indexOf('set_') === 0) {
        typeImage = type.substring(4);
    }

    if (!products[type]) {
        return <Redirect to='/'/>
    }

    const product = products[type];
    const display = window.innerWidth >= 768 ? 'desktop' : 'mobile';

    const startAnimation = () => {
        setTimeout(() => {
            setDisplayed(true);
        }, 1000);
    }

    setTimeout(() => {
        if (!errorRef.current && !playedRef.current) {
            setError(true);
            errorRef.current = true;
            setTimeout(() => {
                setDisplayed(true);
            }, 500);
        }
    }, 2000);

    return (
        <>
            <div id={"animationStrate1"}>

                <div className={"header"}>
                    <div className={"video-container"}>
                        {error ?
                            <div className={"thumb-error"}/> :
                            <video id={"videoAnimation"}
                                   className={"video"}
                                   muted={true}
                                   preload={"auto"}
                                   playsInline={true}
                                   autoPlay={true}
                                   onError={() => {
                                       setError(true);
                                       errorRef.current = true;
                                       setDisplayed(true);
                                   }} onPlay={() => {
                                setPlayed(true);
                                playedRef.current = true;
                                startAnimation();
                            }}>
                                <source src={require("../../images/" + display + "/animation.mp4")}/>
                            </video>
                        }
                    </div>

                    <div className={"overlap"}>

                        <div className={"product " + (displayed ? 'displayed' : '')}>
                            <div className={"image-bloc"}>
                                <img className={"image " + (displayed ? 'animated' : '')}
                                     src={process.env.REACT_APP_URL + "/file/product/" + typeImage + ".jpg"}/>
                            </div>
                            <div className={"title"} dangerouslySetInnerHTML={{__html: product.title}}></div>
                            { product.desc ? <div className={"description"}>{product.desc}</div> : '' }
                        </div>

                    </div>

                    <div className={"info"}>
                        <div className={"icon"}></div>
                        You will be contacted within 7 working days to confirm if your registration is valid or not.
                    </div>

                </div>

            </div>
        </>
    )

}

export default Animation;
