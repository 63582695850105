import React from 'react';
import {HashLink as Link} from "react-router-hash-link"
import {withRouter} from 'react-router-dom';
import ReactGA from "react-ga"
import {checkOpeStart} from "./services/functions"

class Footer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contact: true
        };
    }

    logGABack() {
        ReactGA.event({
            category: 'Click',
            action: 'Click back to smythstoys.com'
        });
    }

    render() {
        const opeStart = checkOpeStart();
        this.state.contact = (this.props.location.pathname !== '/registermypurchase' && this.props.location.pathname !== '/contact-us' && opeStart);

        return (
            <footer>

                <div id={"footer_wrapper"}>

                    {this.state.contact ? (

                        <div id={"contact"} className={"contact"}>
                            <div className={"title"}><span>need</span> more help?</div>
                            <div className={"subtitle"}>Please contact us!</div>

                            <div className={"image"}></div>

                            <div className={"info"}>
                                If you have any questions that our <Link to="/faq">FAQ’s</Link> haven’t been able
                                to&nbsp;
                                answer or any technical issues regarding this promotion, please contact<br/>
                                TLC Marketing via the form <Link to="/contact-us">here</Link>.
                            </div>
                        </div>

                    ) : ''}

                    <div className={"footer_logos_top"}></div>
                    <div className={"footer_back"}>
                        <div className={"footer_back_inner"}>
                            <a onClick={() => {
                                this.logGABack()
                            }} className="cta_back" href={"https://www.smythstoys.com/uk/en-gb/lego-shop"} target="_blank">
                                Go back to Smyths Toys
                            </a>
                        </div>
                    </div>
                    <div className={"footer_logos"}>
                        <div className={"footer_logos_inner"}>
                            <a className={"footer_logo_lego logo"}
                               href={"https://www.lego.com/en-gb"}
                               target={"_blank"}
                               rel={"noreferrer"}></a>
                            <a className={"footer_logo_lego_smyths logo"}
                               href={"https://www.smythstoys.com/uk/en-gb/lego-shop"}
                               target={"_blank"}
                               rel={"noreferrer"}></a>
                            <a className={"footer_logo_tlc logo"}
                               href={"https://www.tlcmarketing.com/united-kingdom/"}
                               target={"_blank"}
                               rel={"noreferrer"}/>
                        </div>
                    </div>

                </div>
            </footer>
        );

    }

}

export default withRouter(Footer);
