import React from 'react';

const FinOpe = () => {

    return (

        <div id={"oops_heander"}>

            <h1>
                <div className={"m"}>The</div>
                <div className={"xl"}>LEGO® Gift Giveaway</div>
                <div className={"m"}>campaign has now ended...</div>
            </h1>

            <div className={"image"}></div>
            <div className={"shop"}>
                <div className={"text"}>
                    Find and shop the latest LEGO® range<br/>
                    on our LEGO brand store
                </div>
                <a href={"https://www.smythstoys.com/uk/en-gb/lego-shop"} target={"_blank"}>SHOP NOW</a>
            </div>
        </div>

    );
}
export default FinOpe;
