import React from 'react';

const OpeStart = () => {

    return (

        <div id={"oops_heander"} className={"mb-5"}>

            <h1>
                <div className={"m"}>Please be patient,</div>
                <div className={"xl"}>LEGO® Gift Giveaway</div>
                <div className={"m"}>campaign has not yet started...</div>
            </h1>

            <div className={"image"}></div>
            <div className={"shop"}>
                <div className={"text"}>
                    Find and shop the latest LEGO® range<br/>
                    on our LEGO brand store
                </div>
                <a href={"https://www.smythstoys.com/uk/en-gb/lego-shop"}>SHOP NOW</a>
            </div>
        </div>

    );
}
export default OpeStart;
