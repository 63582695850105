import React from 'react';
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';


import Routes from './routes';

import Header from "./header"
import Footer from "./footer"

import CookieBanner from "./_cookie.jsx"


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.last_route = "";
    }

    render() {

        let route = this.props.location.pathname.split("/")[1]
        route = route === "" ? "index" : route

        if (this.last_route !== "" && route !== this.last_route) {
            //pageview GA
            ReactGA.pageview(window.location.pathname + window.location.search);
            window.scrollTo(0, 0)
        }


        this.last_route = route;

        // <ScrollTop/>
        return (
            <div className="page_wrapper" id={"page_" + route + "_wrapper"}>
                <Header/>
                <div className="content_wrapper" id={"content_" + route + "_wrapper"}>
                    <Routes/>
                </div>
                <Footer/>
                <CookieBanner/>
            </div>
        );

    }
}

export default withRouter(Layout);
