import React from 'react';

import faq_data from '../../file/faq.json';

const FaqElement = (props) => {

    function toggleFaqElement(event) {

        let faqBlock = event.target.parentNode;
        let faqBlockState = faqBlock.classList.contains("open");

        let allFaqBlock = document.getElementsByClassName("faq_block");

        [].forEach.call(allFaqBlock, function (b) {
            b.classList.remove("open");
        });

        if (faqBlockState === true)
            faqBlock.classList.remove("open");
        else
            faqBlock.classList.add("open");
    }

    return (

        <div className="faq_block">
            <div className="noselect faq_title" onClick={(e) => toggleFaqElement(e)}
                 dangerouslySetInnerHTML={{__html: props.title}}></div>
            <div className={"faq_content"}>
                {props.content.map((p, key) => (
                    <span key={key} dangerouslySetInnerHTML={{__html: p}}/>
                ))}
            </div>
        </div>

    );
};

const Faq = () => {
    return (
        <div id={"faqStrate1"} className={"main_header"}>
            <div className={"main-container"}>

                <div className={"main-image"}></div>

                <div className={"main-title"}>
                    <h1>FAQ'S</h1>
                </div>

                <div id={"faq_block_wrapper"}>
                    {faq_data.faq.map((item, key) => (
                        <FaqElement key={key} title={item.title} content={item.content}/>))}
                </div>

            </div>
        </div>
    );
};

export default Faq;
