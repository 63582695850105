import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import CustomFieldText from '../form/CustomFieldText';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {

    const {register, handleSubmit, errors} = useForm({validateCriteriaMode: "all"});
    const recaptchaRef = useRef(null);
    const submitRef = useRef(null);

    const [merci, setMerci] = useState(false);

    //form validation
    const onSubmit = data => {

        submitRef.current.setAttribute("disabled", "disabled");

        // contrôle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/submit_contact.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    setMerci(true);
                } else {
                    //setFormError(json.error);
                    alert(json.error);
                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <div id="contactStrate1" className={"main_header"}>

            <div className={"main-container"}>

                <div className={"main-image"}></div>

                {!merci &&
                    <>
                        <div className={"main-title"}>
                            <h1><span>need</span> more help?</h1>
                            <div className={"subtitle"}>Please contact us!</div>
                        </div>

                        <div className={"desc"}>
                            If you have any questions that our <Link to={"/faq"}>FAQ’s</Link> haven’t been able to answer or any
                            technical issues regarding this promotion, please contact<br/>TLC Marketing via the form below.
                        </div>

                        <form id={"form_contact"} name={"form_contact"} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                            <CustomFieldText name="firstname" label="First name*" register={register({
                                required: {value: true, message: Helper.EmptyErrorMessages.firstname},
                                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.firstname},
                                minLength: {value: 2, message: Helper.InvalidErrorMessages.firstname}
                            })} error={errors.firstname && errors.firstname.message}/>

                            <CustomFieldText name="lastname" label="Last name*" register={register({
                                required: {value: true, message: Helper.EmptyErrorMessages.lastname},
                                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.lastname},
                                minLength: {value: 2, message: Helper.InvalidErrorMessages.lastname}
                            })} error={errors.lastname && errors.lastname.message}/>

                            <CustomFieldText name="mail" label="Email*"
                                             register={
                                                 register({
                                                     required: true,
                                                     pattern: Pattern.emailPattern,
                                                     validate: {
                                                         domain: (value) => Helper.checkMailDomain(value)
                                                         /*confirm : checkMailConfirm*/
                                                     }
                                                 })}
                                             error={[
                                                 errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                                 errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                                 errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain
                                             ]}/>

                            <CustomFieldText name="mail_confirm" label="Confirm email*"
                                             register={
                                                 register({
                                                     required: true,
                                                     pattern: Pattern.emailPattern,
                                                     validate: {
                                                         domain: (value) => Helper.checkMailDomain(value),
                                                         confirm: Helper.checkMailConfirm
                                                     }
                                                 })}
                                             error={[
                                                 errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                                 errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                                 errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                                 errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                             ]}/>


                            <div className={"form_row_wrapper"} id={"form_row_wrapper_reason"}>
                                <div className={"label_wrapper"}>
                                    <label htmlFor={"reason"}>
                                        Reason for question<span>*</span>
                                    </label>
                                </div>
                                <div className={"input_wrapper"}>
            <textarea name={"reason"} id={"reason"} rows={5}
                      ref={register({required: {value: true, message: Helper.EmptyErrorMessages.reason}})}/>
                                    <div className="error">{errors.reason && errors.reason.message}</div>
                                </div>
                            </div>

                            <div className={"mention_obligatoire"}>
                                *Required fields
                            </div>

                            {/* recaptcha */}
                            <div className="text-center" id={"recaptcha_wrapper"}>
                                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b"
                                           ref={recaptchaRef}/>
                                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
                            </div>

                            {/* server error */}
                            <div className="text-center">
                                <button className={"cta"} id={"submit_cta"} ref={submitRef}>submit</button>
                            </div>

                        </form>
                    </>
                }

                {merci &&
                    <p>Thank's for contacting us, <br/>TLC Marketing will come back to you as soon as possible.</p>
                }

            </div>

        </div>
    );
};

export default Contact;
