export const checkOpeStop = () => {

    //si la constante n'est pas défnie on retourne true
    if (!("REACT_APP_DATE_OPE_STOP" in process.env))
        return false

    //let start_date_string = "05/06/2020".split("/");
    let stop_date_string = process.env.REACT_APP_DATE_OPE_STOP.split("/")
    let stop_date = new Date(stop_date_string[2], stop_date_string[1] - 1, stop_date_string[0])
    let now_date = new Date()

    return (now_date >= stop_date)
}

export const checkOpeStart = () => {

    //si la constante n'est pas défnie on retourne true
    if (!("REACT_APP_DATE_OPE_START" in process.env))
        return true

    //let start_date_string = "05/06/2020".split("/");
    let start_date_string = process.env.REACT_APP_DATE_OPE_START.split("/")
    let start_date = new Date(start_date_string[2], start_date_string[1] - 1, start_date_string[0])
    let now_date = new Date()

    return (now_date >= start_date)
}
