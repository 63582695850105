import * as Helper from "../form/form_inscription_helper"
import {useForm} from "react-hook-form"
import React, {useState, useRef} from "react"
import {Form} from "react-bootstrap"
import CustomComboBox from "../form/CustomComboBox"
import CustomFieldText from "../form/CustomFieldText"
import CustomUploadField from "../form/CustomUploadField"
import * as Pattern from "../form/form_pattern_helper"
import BirthDayField from "../form/BirthDayField"
import ReactGA from "react-ga"
import ReCAPTCHA from "react-google-recaptcha";

const Participer = () => {

    const {register, handleSubmit, errors, clearError, setValue} = useForm({validateCriteriaMode: "all"});
    const submitRef = useRef(null);
    const recaptchaRef = useRef(null);
    const [file, setFile] = useState(null);

    function setFilename(event) {
        setValue("filename", event.target.value);
        if (event.target.files[0] !== undefined && Helper.checkIsUploadable(event.target.files[0])) {
            setFile(event.target.files[0]);
            clearError("filename");
        }
    }

    function logGA() {
        ReactGA.event({
            category: 'Clic',
            action: 'Clic participation'
        });
    }

    //form validation
    const onSubmit = data => {

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        submitRef.current.setAttribute("disabled", "disabled");
        logGA();

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("receipt", file);
        formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/submit_form.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                if (json.success === true) {
                    window.scrollTo(0, 0);
                    window.location.href = '/animation/' + json.type;
                } else {
                    alert(json.error);
                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            }).catch(() => {
            submitRef.current.removeAttribute("disabled");
            window.grecaptcha.reset();
        });

    };

    const checkParticipations = () => {
        fetch(process.env.REACT_APP_API_URL + '/check_participation.php',
            {
                method: 'GET',
            })
            .then(response => response.json())
            .then(json => {
                if (json.success === false) {
                    window.location.href = '/operation-full';
                }
            }).catch(() => {});
    };

    checkParticipations();

    return (
        <>

            <div id={"participerStrate1"} className={"main_header"}>

                <div className={"main-container"}>
                    <div className={"main-image"}></div>

                    <div className={"main-title"}>
                        <h1><span>please</span> complete</h1>
                        <div className={"subtitle"}>the registration form below to enter<br />and discover your surprise gift!</div>
                    </div>

                    <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

                        <CustomComboBox name="title" id="title" label={"Title*"} placeholder={"Choice"}
                                        options={[
                                            ["Miss", "Miss"],
                                            ["Mrs", "Mrs"],
                                            ["Mr", "Mr"],
                                            ["Prefer not to say","Prefer not to say"]
                                        ]}
                                        register={register({
                                            required: {
                                                value: true,
                                                message: Helper.EmptyErrorMessages.title
                                            }
                                        })}
                                        error={errors.title && errors.title.message}/>

                        <CustomFieldText name="firstname" label="First name*" register={register({
                            required: {value: true, message: Helper.EmptyErrorMessages.firstname},
                            pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.firstname},
                            minLength: {value: 2, message: Helper.InvalidErrorMessages.firstname}
                        })} error={errors.firstname && errors.firstname.message}/>

                        <CustomFieldText name="lastname" label="Last name*" register={register({
                            required: {value: true, message: Helper.EmptyErrorMessages.lastname},
                            pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.lastname},
                            minLength: {value: 2, message: Helper.InvalidErrorMessages.lastname}
                        })} error={errors.lastname && errors.lastname.message}/>

                        <BirthDayField
                            label={"Date of birth<span>*</span>"}
                            register={
                                register({
                                    required: true,
                                    validate: Helper.checkBirthDate
                                })}
                            error={[
                                (errors.jour_naissance && errors.jour_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                                (errors.mois_naissance && errors.mois_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                                (errors.annee_naissance && errors.annee_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance),
                                (errors.jour_naissance && errors.jour_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                                (errors.mois_naissance && errors.mois_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                                (errors.annee_naissance && errors.annee_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age)
                            ]}/>

                        <CustomFieldText name="mail" label="Email*"
                                         register={
                                             register({
                                                 required: true,
                                                 pattern: Pattern.emailPattern,
                                                 validate: {
                                                     domain: (value) => Helper.checkMailDomain(value)
                                                     /*confirm : checkMailConfirm*/
                                                 }
                                             })}
                                         error={[
                                             errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                             errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                             errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                             errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                         ]}/>

                        <CustomFieldText name="mail_confirm" label="Confirm email*"
                                         register={
                                             register({
                                                 required: true,
                                                 pattern: Pattern.emailPattern,
                                                 validate: {
                                                     domain: (value) => Helper.checkMailDomain(value),
                                                     confirm: Helper.checkMailConfirm
                                                 }
                                             })}
                                         error={[
                                             errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                             errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                             errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                             errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                         ]}/>

                        <div className={"info_email"}>
                            <div className={"icon"}></div>
                            <b>Please note:</b> the email address you entered in the field above will be used to send you your surprise gift or to contact you regarding your gift!
                        </div>

                        <CustomUploadField name={"filename"}
                                           label={"Receipt*<br><small>An image of your purchase receipt or order confirmation is required. This will need to clearly show the date when you made the purchase, the store it was bought from and the LEGO® transaction. Without this information visible, we cannot accept your registration.<br>Accepted formats: jpeg, png, pdf, 5 MB maximum.</small>"}
                                           register={register({
                                               required: {
                                                   value: true,
                                                   message: Helper.EmptyErrorMessages.filename
                                               }
                                           })} error={errors.filename && errors.filename.message}
                                           onchangeHandler={setFilename}/>

                        <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1"
                                   ref={register({required: true})}/>

                            <label htmlFor="optin_reglement">
                                I confirm that I have read and agree to comply with the <a rel={"noreferrer"} href={"/file/terms-conditions.pdf"} target={"_blank"}>Terms and Conditions</a> and have viewed the <a rel={"noreferrer"} href={"/file/privacy-cookies-policy.pdf"} target={"_blank"}>Privacy Policy</a>
                                <span>*</span>.
                            </label>
                            <div
                                className="error">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>

                        </div>

                        <div id={"optin_brand_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_brand" id="optin_brand" value="1" ref={register()}/>

                            <label htmlFor="optin_brand">
                                I am happy to receive the latest news and offers from the LEGO® brand by email.
                            </label>
                        </div>

                        <div id={"optin_brand_second_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_brand_second" id="optin_brand_second" value="1" ref={register()}/>

                            <label htmlFor="optin_brand_second">
                                I am happy to receive the latest news and offers from Smyths Toys by email.
                            </label>
                        </div>

                        <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_tlc" id="optin_tlc" value="1" ref={register()}/>

                            <label htmlFor="optin_tlc">
                                I am happy to be contacted and receive the latest news / offers from TLC Marketing by email.
                            </label>
                        </div>

                        <div className={"mention_obligatoire"}>
                            *Required fields.
                        </div>

                        {/* recaptcha */}
                        <div className="text-center" id={"recaptcha_wrapper"}>
                            <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b"
                                       ref={recaptchaRef}/>
                            <div className="error text-center" id="recaptcha_error">&nbsp;</div>
                        </div>

                        {/* server error */}
                        <div className="text-center">
                            <button className={"cta_black"} id={"submit_cta"} ref={submitRef}>register my purchase</button>
                        </div>


                    </Form>

                </div>

            </div>

        </>
    );

};

export default Participer;
