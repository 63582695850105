import React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {HashLink as Link} from 'react-router-hash-link';
import {checkOpeStart} from "./services/functions"

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menu_open: false,
        };

        this.toggleMenuState = this.toggleMenuState.bind(this);
    }

    toggleMenuState() {

        if (!this.state.menu_open) {
            this.setState({menu_open: true});
        } else {
            this.setState({menu_open: false});
        }
    }

    detectIE() {

        var ua = window.navigator.userAgent;

        // IE 10 or older => return version number
        var msie = ua.indexOf('MSIE');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        // IE 11 => return version number
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        // Edge (IE 12+) => return version number
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    render() {
        const opeStart = checkOpeStart();

        const pathName = this.props.location.pathname;
        const isSmall = (pathName.indexOf('/animation/') === 0);

        let IE_warning;

        if (this.detectIE() !== false)
            IE_warning =
                <div id="IE_warning">Ce site est optimisé pour fonctionner sur les navigateurs les plus récents
                    (chrome,
                    firefox, microsoft edge , ..), merci de mettre à jour votre navigateur. </div>;
        else
            IE_warning = "";

        return (
            <>
                <header id="header">
                    {IE_warning}

                    <div id="header_wrapper">

                        <div className={"header_body" + (isSmall ? ' is-small' : '')}>
                            {opeStart ?
                                <div id="burger" isopen={this.state.menu_open ? "1" : "0"}
                                     onClick={this.toggleMenuState}>
                                    <div/>
                                    <div/>
                                    <div/>
                                </div>
                                : ''}

                            <NavLink className="logo" to="/"/>
                            <div className={"logo_2"}></div>

                        </div>

                    </div>
                </header>

                <div id="menu" isopen={this.state.menu_open ? "1" : "0"}>

                    <div id="menu_overlay"/>

                    <nav>

                        <div className={"inner-nav"}>

                            <button onClick={this.toggleMenuState} id={"menu_close"}/>

                            <NavLink className="logo" to="/"/>

                            <div className={"logo_2"}></div>

                            <div className={"navs"}>
                                <Link onClick={this.toggleMenuState} to="/">
                                    HOME
                                </Link>
                                <Link onClick={this.toggleMenuState} to="/#howtoenter" smooth>
                                    HOW TO ENTER?
                                </Link>
                                <Link onClick={this.toggleMenuState} to="/#gifts" smooth>
                                    THE SURPRISE gifts
                                </Link>
                                <Link onClick={this.toggleMenuState} to="/registermypurchase">REGISTER MY
                                    PURCHASE</Link>
                                <Link onClick={this.toggleMenuState} to="/contact-us">CONTACT US</Link>
                                <Link onClick={this.toggleMenuState} to="/faq">FAQ'S</Link>
                            </div>
                            <div className={"navs"}>
                                <a onClick={this.toggleMenuState}
                                   href="/file/terms-conditions.pdf"
                                   target={"_blank"}
                                   className={"small"}>TERMS AND CONDITIONS</a>
                                <a onClick={this.toggleMenuState}
                                   href="/file/privacy-cookies-policy.pdf"
                                   target={"_blank"}
                                   className={"small"}>Privacy and Cookies Policy</a>
                            </div>

                        </div>

                    </nav>
                </div>
            </>
        );

    }
}

export default withRouter(Header);
