import React from 'react';

//champs texte classique
const CustomUploadField = ({label, name, id = name, onchangeHandler = null, register, error, className = ""}) => {

    label = label.replace("*", "<span>*</span>");

    return (
        <div className={"form_row_wrapper upload_wrapper"} id={"form_row_wrapper_" + name}>
            <div className={"label_wrapper"}>
                <label htmlFor={id} dangerouslySetInnerHTML={{__html: label}}/>
            </div>
            <div className={"input_wrapper"}>
                <div className={"row-upload"}>
                    <input type="file" className="d-none" id={id + "_file"} name={name + "_file"}
                           accept="image/png, image/jpeg, .pdf" onChange={onchangeHandler}/>
                    <input className={className} type={"text"} id={id} name={name} disabled={true} ref={register} placeholder={"Browse..."}/>
                    <label id={id + "_cta"} className={"label-upload"} htmlFor={id + "_file"}>UPLOAD</label>
                </div>
                <div className="error">{error}</div>
            </div>
        </div>
    );
}

export default CustomUploadField;
