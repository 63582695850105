import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';

//champs date de naissance
const BirthDayField = ({label, register, error, onChange}) => {

  let day   = [];
  let month = [];
  let year  = [];

  for (let i = 1; i <= 31; i++) {

    if (i < 10) i = "0" + i;
    day.push(<option value={i} key={"day_" + i}>{i}</option>);
  }

  for (let i = 1; i <= 12; i++) {

    if (i < 10) i = "0" + i;
    month.push(<option value={i} key={"month_" + i}>{i}</option>);
  }

  for (let i = 2020; i >= 1900; i--)
    year.push(<option value={i} key={"year_" + i}>{i}</option>);

  return (
    <div className={"form_row_wrapper"}>

      <div className={"label_wrapper"}>
        <label dangerouslySetInnerHTML={{__html: label}}/>
      </div>

      <div className={"input_wrapper birthday_wrapper"}>
        <div>
          <select name="jour_naissance" id="jour_naissance" className={"default"} onChange={onChange} ref={register}
                  required={true} defaultValue={""}>
            <option disabled value={""}>DD</option>
            {day}
          </select>

          <select name="mois_naissance" id="mois_naissance" className={"default"} onChange={onChange} ref={register}
                  required={true} defaultValue={""}>
            <option disabled value={""}>MM</option>
            {month}
          </select>

          <select name="annee_naissance" id="annee_naissance" className={"default"} onChange={onChange} ref={register}
                  required={true} defaultValue={""}>
            <option disabled value={""}>YYYY</option>
            {year}
          </select>
        </div>

        <div className="error">{error}</div>
      </div>

    </div>
  );
};

export default BirthDayField;
