//message d'erreur
export let EmptyErrorMessages = {
    title: "Please fill in your title",
    lastname: "Please fill in your last name",
    firstname: "Please fill in your first name",
    date_naissance: "Please fill in your date of birth",
    mail: "Please fill in your email address",
    mail_confirm: "Please confirm your email address",
    filename: "Please upload an image of your receipt",
    optin_reglement: "Please accept the Terms and Conditions",
    generic: "Please fill in this info to continue",
    recaptcha: "Please check this box to continue",
    reason: "Please fill in reason for your question",
};

export let InvalidErrorMessages = {
    lastname: "Last name is invalid",
    firstname: "First name is invalid",
    date_naissance: "Date of birth is invalid",
    date_naissance_age: "You must be aged 18 or over to enter",
    mail: "Email address is invalid",
    mail_dif: "Email address and confirm email address do not match",
    mail_domain: "You must fill in a non ephemeral email address",
};

//------------------------
//	on change field mail
//------------------------
export function checkMailConfirm() {

    let mail = document.getElementById("mail").value;
    let mail_confirm = document.getElementById("mail_confirm").value;

    //check de la correspondance des deux emails
    return !(mail.length !== 0 && mail_confirm.length !== 0 && mail !== mail_confirm);

}

export function checkMailDomain(value) {

    let domain = value.substring(value.indexOf('@') + 1);
    let forbiddenMailDomain = [
        'yopmail.com',
        'clipmail.eu',
        'clrmail.com',
        'get2mail.fr',
        'mail-temporaire.fr',
        'spamfree24.org',
        'jetable.org',
        'lala.fr'];

    //check de l'extension du mail
    return (!forbiddenMailDomain.includes(domain));
}

//------------------------
//	controle age participant
//------------------------
export function checkBirthDate() {

    let day = document.getElementById("jour_naissance").value;
    let month = document.getElementById("mois_naissance").value;
    let year = document.getElementById("annee_naissance").value;

    if (day.length !== 0 && month.length !== 0 && year.length !== 0) {

        let birthday = new Date(year, month - 1, day);

        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs);
        let diffYears = Math.abs(ageDate.getUTCFullYear() - 1970);

        console.log(diffYears);

        if (diffYears < 18) {
            return false;
        }
    }

    return true;
}

//------------------------
//	controle du fichier avant upload
//------------------------
export function checkIsUploadable(fileToUpload) {

    //check des extensions
    let validExtensions = [
        'jpg',
        'jpeg',
        'png',
        'pdf'];
    let extension = fileToUpload.name.substr(fileToUpload.name.lastIndexOf(".") + 1).toLowerCase();
    let file_size = fileToUpload.size;
    let max_size = 5 * 1024 * 1024;

    if (!validExtensions.includes(extension) || file_size > max_size) {
        return false;
    }

    return true;
}
