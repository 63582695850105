import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import RouteWrapper from './Route';
import Home from '../pages/home';
import FinOpe from '../pages/operation-end';
import Faq from '../pages/faq';
import Participer from "../pages/participer";
import {checkOpeStop, checkOpeStart} from "../services/functions";
import OperationFull from "../pages/operation-full"
import Contact from "../pages/contact"
import Animation from "../pages/animation"
import OpeStart from "../pages/operation-start"

export default function Routes() {

  let operation_terminee = checkOpeStop();
  let operation_demarree = checkOpeStart();

  console.log(operation_demarree);

  return(
      <Switch>

        {/*visible si ope demarre*/}
        { operation_demarree && <RouteWrapper path="/" exact component={Home} /> }
        { !operation_demarree && <RouteWrapper path="/" exact component={OpeStart} /> }
        { operation_demarree && <RouteWrapper path="/faq" component={Faq} /> }
        { operation_demarree && !operation_terminee && <RouteWrapper path="/registermypurchase" component={Participer} /> }
        { operation_demarree && !operation_terminee && <RouteWrapper path="/animation/:type" component={Animation} /> }
        { operation_demarree && <RouteWrapper path="/contact-us" component={Contact} /> }

        <RouteWrapper path="/operation-ended" component={FinOpe} />
        <RouteWrapper path="/operation-full" component={OperationFull} />
        <RouteWrapper path="/operation-start" component={OpeStart} />

        {/* redirection */}
        { operation_demarree && !operation_terminee && <Redirect to="/" /> }
        { !operation_demarree && <Redirect to="/" /> }
        { operation_terminee && <Redirect to="/operation-ended" /> }
        
      </Switch>
    )
}
